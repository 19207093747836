<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col sm="6" md="8">
        <div>
          <h1>EVALUATION PREP</h1>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationDetail',
                params: { id: $route.params.id },
              })
            "
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <customer-info />

    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <div class="pa-2 table-header">MANUAL OVERRIDE</div>
          </v-col>

          <v-col cols="12" sm="4">
            <date-time :time="true" v-model="confirmationStep.time">
            </date-time>
          </v-col>

          <v-col cols="12" sm="4">
            <date-time :date="true" v-model="confirmationStep.date">
            </date-time>
          </v-col>

          <v-col cols="12" sm="4" class="pt-7">
            <v-btn color="primary" @click="dialog = true">Change</v-btn>
          </v-col>
        </v-row>
        <div class="mt-8 pa-2 table-header">SELECT FROM OPEN SLOTS</div>
      </v-col>
    </v-row>

    <datatable
      :headers="headers"
      :staticData="items"
      :componentURL="componentURL"
    ></datatable>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="450">
        <v-card>
          <v-card-title class="headline"> NEED CONFIRMATION STEP </v-card-title>
          <v-card-text class="pt-5">
            OLD EVAL: {{ evaluation_info.eval_date }}
            {{ evaluation_info.eval_time }}
          </v-card-text>
          <v-card-text>
            NEW EVAL: {{ confirmationStep.date }} {{ confirmationStep.time }}
          </v-card-text>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-checkbox label="Make Old Eval Slot Available" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import DateTime from "../../components/DateTime/DateTime";
import CustomerInfo from "./CustomerInfo";

export default {
  name: "EvaluationReschedule.vue",
  components: { CustomerInfo, Datatable, DateTime },
  data() {
    return {
      dialog: false,
      componentURL: "",
      evaluation_info: {
        eval_date: "9-1-2020",
        eval_time: "9:00 PM",
      },
      confirmationStep: {
        time: "",
        date: "",
      },
      headers: [
        { text: "Time", value: "time" },
        { text: "Date", value: "date" },
        { text: "Min/Before", value: "before" },
        { text: "Min/After", value: "after" },
      ],
      items: [
        {
          time: "8:00 AM",
          date: "9/14/2020",
          before: "12",
          after: "25",
        },
        {
          time: "10:00 AM",
          date: "9/14/2020",
          before: "45",
          after: "12",
        },
        {
          time: "12:00 PM",
          date: "9/14/2020",
          before: "72",
          after: "64",
        },
      ],
    };
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
